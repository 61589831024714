@import '~@quero/zilla-core/src/utils/_index.scss';

@keyframes spinning {
  from { transform:rotate(0) }
  to { transform:rotate(3turn) }
}

@mixin spinner__line($radius, $width) {
  border-radius: $radius;
  border-width: $width;
  border-style: solid;
  content: "";
  height: $radius * 2;
  width: $radius * 2;
  position: absolute;
  margin-left: -$radius;
}

@mixin _spinner($size) {
  $spinner-diameter: $size;
  $spinner-radius: $spinner-diameter / 2;
  $spinner-line-width: (44 / 11) * ($spinner-diameter / 44);

  display: inline-block;
  height: $spinner-diameter;
  width: $spinner-diameter;

  &:after {
    @include spinner__line($spinner-radius, $spinner-line-width);
    border-color: var(--color-brand-primary-pure) transparent transparent;
    /* [0.51, 0.09, 0.21, 0.8] */
    animation: spinning 2.4s cubic-bezier(0.51, 0.15, 0.21, 0.8);
    animation-iteration-count: infinite;
  }

  &:before {
    @include spinner__line($spinner-radius, $spinner-line-width);
    border-color: var(--color-brand-primary-pure) var(--color-neutral-gray-lighter) var(--color-neutral-gray-lighter);
    /*  [0.41, 0.26, 0.2, 0.62] */
    animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.21, 0.62);
    animation-iteration-count: infinite;
  }
}

$default-spinner-size: 44px;

.spinner.spinner--extra-large {
  @include _spinner($default-spinner-size * 4);
}

.spinner.spinner--extra-large {
  @include _spinner($default-spinner-size * 2);
}

.spinner {
  @include _spinner($default-spinner-size);
}

.spinner.spinner--small {
  @include _spinner($default-spinner-size / 2);
}

.spinner.spinner--extra-small {
  @include _spinner($default-spinner-size / 4);
}

.spinner__card {
  align-self: center;
  align-items: center;

  padding: 0 $space-st $space-st $space-st !important;
  display: flex;
  flex-direction: column;
  max-width: 276px;

  > :first-child {
    margin: $space-st - 2 $space-st - 2 0;
    &:after, &:before {
      margin-left: 0 !important;
    }
  }

  @media (min-width: $screen-md-min) {
    max-width: 482px;
  }
}

.spinner__wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
}

.spinner__text {
  margin-top: $space-st - 2;
  text-align: center;
}

.spinner__standalone {
  margin-top: $space-j;
}
