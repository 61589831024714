@import '~@quero/zilla-core/src/utils/_index.scss';

.flex {
  display: flex;
}

.block {
  display: block;
}

.hidden {
  display: none !important;
}

.visible {
  display: block !important;
}

.hide-sm {
  @media (min-width: $screen-sm) {
    display: none !important;
  }
}

.show-sm {
  display: none !important;

  @media (min-width: $screen-sm) {
    display: block !important;
  }
}

.hide-md {
  @media (min-width: $screen-md-min) {
    display: none !important;
  }
}

.show-md {
  display: none !important;

  @media (min-width: $screen-md-min) {
    display: block !important;
  }
}

.show-md-flex {
  display: none !important;

  @media (min-width: $screen-md-min) {
    display: flex !important;
  }
}

.hide-lg {
  @media (min-width: $screen-lg) {
    display: none !important;
  }
}

.show-lg {
  display: none !important;

  @media (min-width: $screen-lg) {
    display: block !important;
  }
}
