@import '~@quero/zilla-core/src/utils/_index.scss';

.ca-button-wrapper {
  margin-top: $space-st;

  .z-button { display: block; width: 100%;  }

  @media (min-width: $screen-md-min) {
    display: flex;
    justify-content: flex-end;
    margin-top: $space-l;

    .z-button { display: initial; width: 180px; }
    .z-button--secondary { width: 140px; }
  }
}

.ca-secondary-link {
  border-width: 0 !important;
  text-decoration: underline;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
  }

  &.ca-secondary-link--text-only {
    background-color: transparent !important;
    text-decoration: none;
  }
}
