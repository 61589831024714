@import '~@quero/zilla-core/src/utils/_index.scss';

$ca-rail-width: 360px;

.ca-section {
  padding: $space-l 15px;
}

.ca-section__positioner {
  @media (min-width: $screen-md-min) {
    display: flex;
    vertical-align: top;
    justify-content: center;

    & > * {
      display: inline-block;
    }
  }
}

.ca-section__main {
  margin: initial;
  width: 100%;

  @media (min-width: $screen-md-min) {
    max-width: 1170px;
  }
}

.ca-section__rail--right {
  display: none;

  @media (min-width: $screen-md-min) {
    display: initial;
    margin-left: $space-l;
    width: $ca-rail-width;
  }
}
