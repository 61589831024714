@import '~@quero/zilla-core/src/utils/_index.scss';

.ca-text--upper {
  text-transform: uppercase;
}

.ca-text--tabular-number {
  font-variant-numeric: tabular-nums;
}

.ca-text--helper {
  color: var(--color-neutral-gray-darker);
}
