@import '~@quero/zilla-core/src/utils/_index.scss';

$sizes: xxs, xs, s, m, st, l, xl, j;
$actualSizes: $space-xxs, $space-xs, $space-s, $space-m, $space-st, $space-l, $space-xl, $space-j;

@each $size in $sizes {
  $i: index($sizes, $size);
  $actualSize: nth($actualSizes, $i);

  .ca-spacer-h-#{$size},
  .ca-spacer-h-#{$size}-l {
    margin-left: $actualSize;
  }

  .ca-spacer-h-#{$size},
  .ca-spacer-h-#{$size}-r {
    margin-right: $actualSize;
  }
}
