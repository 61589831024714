@import '~@quero/zilla-core/src/utils/_index.scss';

.z-card.ca-card--common {
  margin-top: $space-st;
  padding: $space-l $space-st;

  @media (min-width: $screen-md-min) {
    margin-top: $space-l;
    padding: $space-l;
  }
}
