@import '~@quero/zilla-core/src/utils/_index.scss';

.multiselect {
  & .multiselect__tags {
    padding: $space-s $space-s 0 $space-s;
  }
}

.multiselect__spinner {
  border-radius: $border-radius;
  z-index: 1;

  &::before,
  &::after {
    border-color: var(--color-brand-secondary-pure) transparent transparent !important;
  }
}

.multiselect__option {
  min-height: 0 !important;

  & > span {
    white-space: normal !important;
  }
}

.multiselect__select {
  display: none !important;
}

.multiselect__option--highlight {
  background-color: var(--color-brand-secondary-pure) !important;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: inherit !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--color-brand-secondary-pure);
}

input {
  &:invalid {
    box-shadow: none;
  }
}

.wild-p { // TODO verify its usage (might be overriding react-select)
  margin-bottom: 0;
  margin-top: 0;
}

h1 {
  margin: 0;
}
