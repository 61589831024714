@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

[data-theme="undergraduate"] {
  --color-brand-primary-deep: #143182;
  --color-brand-primary-pure: #304ffe;
  --color-brand-primary-clear: #97b4ff;
  --color-brand-secondary-deep: #993d00;
  --color-brand-secondary-pure: #fa6400;
  --color-brand-secondary-clear: #ffa366;
  --color-font-strong: #1f2d30;
  --color-font-soft: #5e686a;
  --color-font-interactive: #143182;
  --color-font-inverted: #ffffff;
  --color-font-success: #148246;
  --color-font-warning: #b35900;
  --color-font-danger: #990000;
  --color-font-info: #003d52;
  --color-neutral-black: #1f2d30;
  --color-neutral-white: #ffffff;
  --color-neutral-gray-lightest: #fbfbfb;
  --color-neutral-gray-lighter: #eeefef;
  --color-neutral-gray-light: #cacdce;
  --color-neutral-gray-clear: #a6abac;
  --color-neutral-gray-dark: #82898b;
  --color-neutral-gray-darker: #5e686a;
  --color-neutral-gray-darkest: #3b4749;
  --color-support-success-deep: #148246;
  --color-support-success-pure: #50b43c;
  --color-support-success-clear: #bee6b4;
  --color-support-warning-deep: #b35900;
  --color-support-warning-pure: #fcc419;
  --color-support-warning-clear: #fff3bf;
  --color-support-danger-deep: #990000;
  --color-support-danger-pure: #dc1414;
  --color-support-danger-clear: #faaa96;
  --color-support-info-deep: #003d52;
  --color-support-info-pure: #18acc4;
  --color-support-info-clear: #bae2e8;
  --color-social-facebook: #4267b2;
  --color-social-google: #4285f4;
  --color-social-instagram: #c13584;
  --color-social-linkedin: #0a66c1;
  --color-social-slack: #4a154b;
  --color-social-twitter: #1da1f2;
  --color-social-whatsapp: #25d366;
  --color-social-youtube: #ff0000;
  --color-primary: #143182;
  --color-secondary: #993d00;
  --font-weight-normal: 400;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800; }

html,
body {
  background-color: var(--color-neutral-white);
  -ms-overflow-style: none;
  scrollbar-width: none; }
  html::-webkit-scrollbar,
  body::-webkit-scrollbar {
    display: none; }

body.scroll-locked {
  overflow: hidden; }

/** TO BE DELETED SOON **/
.spacer-xs,
.spacer-top-xs {
  margin-top: 4px; }

.spacer-xs,
.spacer-bottom-xs {
  margin-bottom: 4px; }

.spacer-s,
.spacer-top-s {
  margin-top: 8px; }

.spacer-s,
.spacer-bottom-s {
  margin-bottom: 8px; }

.spacer-m,
.spacer-top-m {
  margin-top: 16px; }

.spacer-m,
.spacer-bottom-m {
  margin-bottom: 16px; }

.spacer-st,
.spacer-top-st {
  margin-top: 24px; }

.spacer-st,
.spacer-bottom-st {
  margin-bottom: 24px; }

.spacer-l,
.spacer-top-l {
  margin-top: 32px; }

.spacer-l,
.spacer-bottom-l {
  margin-bottom: 32px; }

.spacer-xl,
.spacer-top-xl {
  margin-top: 40px; }

.spacer-xl,
.spacer-bottom-xl {
  margin-bottom: 40px; }

.spacer-j,
.spacer-top-j {
  margin-top: 64px; }

.spacer-j,
.spacer-bottom-j {
  margin-bottom: 40px; }

.z-text--bold {
  font-weight: 700 !important; }

.z-control-button {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 8px;
  position: relative; }

.z-control-button__input {
  height: 100%;
  left: 0;
  margin: 0;
  min-width: 1px;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  touch-action: manipulation;
  visibility: hidden;
  width: 100%;
  z-index: 2; }
  .z-control-button__input:checked ~ .z-control-button__icon {
    opacity: 1; }
  .z-control-button__input:checked ~ .z-control-button__label::before {
    background-color: rgba(var(--color-brand-primary-deep), 0.08);
    border-color: var(--color-brand-primary-deep); }

.z-control-button__icon {
  border: 1px solid transparent;
  color: var(--color-brand-primary-deep);
  font-size: 1em;
  height: 1em;
  left: 0;
  line-height: normal;
  opacity: 0;
  padding: 1.5px;
  position: absolute;
  text-align: center;
  top: 3px;
  transition: opacity 0.1s, border-color 0.1s;
  width: 1em;
  z-index: 0; }

.z-control-button__label {
  color: var(--color-neutral-black);
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 1.375rem;
  margin: 0;
  position: initial;
  text-indent: 1.4em;
  touch-action: manipulation; }
  .z-control-button__label::before {
    background-color: transparent;
    border: 1px solid var(--color-neutral-black);
    box-sizing: border-box;
    content: '';
    display: block;
    height: 1em;
    left: 0;
    position: absolute;
    top: 3px;
    width: 1em;
    z-index: 0; }

.z-control-button__label--checkbox::before {
  border-radius: 2px; }

.z-control-button__label--radio::before {
  border-radius: 50%; }

.z-title--large-space {
  margin-bottom: 40px; }

.z-title--medium-space {
  margin-bottom: 24px; }

.z-title--small-space {
  margin-bottom: 8px; }

/** TO BE DELETED SOON **/
