@import '~@quero/zilla-core/src/utils/_index.scss';

.ca-position--end {
  display: flex;
  justify-content: flex-end;
}

.ca-position--space-between {
  display: flex;
  justify-content: space-between;
}

.ca-position--center {
  display: flex;
  align-items: center;
}
